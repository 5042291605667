<template>
  <div class="viewtimetable-page">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          disable-search
          ref="viewtimetable-smart-list"
          :endpoint="timetableEndpoint"
          :item-options="{ title: 'title' }"
        >
          <template v-slot:list-item="{ item: timetableItem }">
            <!-- IF ITS A BREAK -->
            <v-card
              v-if="timetableItem.is_break"
              class="my-5 mx-4 post-card"
              :color="daysList[dayNo].color"
            >
              <v-list-item class="px-4 pr-6">
                <v-list-item-content class="ml-0">
                  <v-list-item-title class="white--text"
                    ><h4>Break!</h4></v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="white--text font-weight-medium text-wrap"
                    v-html="TextHelper.linkify(timetableItem.description)"
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-icon class="align-self-center">
                  <div class="time-icon-container mr-n4">
                    <div
                      class="
                        time-container
                        caption
                        mx-1
                        px-1
                        py-1
                        white--text
                        font-weight-bold
                        rounded-lg
                      "
                    >
                      <p class="my-0 py-0 px-0 pb-2">
                        {{
                          moment(timetableItem.start_time, "hh:mm:ss").format(
                            "hh:mm A"
                          )
                        }}
                      </p>
                      <p class="my-0 py-0">
                        {{
                          moment(timetableItem.end_time, "hh:mm:ss").format(
                            "hh:mm A"
                          )
                        }}
                      </p>
                    </div>

                    <intract-drop-down-menu
                      icon-color="white"
                      v-if="!currentUser.is_student"
                      :options-list="getDropdownOptions(timetableItem)"
                    />
                  </div>
                </v-list-item-icon>
              </v-list-item>
            </v-card>

            <!-- ELSE IF ITS NOT A BREAK -->
            <v-card v-else class="my-5 mx-4 post-card">
              <v-list-item class="px-4 pr-6">
                <v-list-item-content class="ml-0">
                  <v-list-item-title class="text-wrap"
                    ><h4>{{ timetableItem.title }}</h4></v-list-item-title
                  >
                  <v-list-item-subtitle class="font-weight-medium text-wrap">{{
                    timetableItem.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon class="align-self-center">
                  <div class="time-icon-container mr-n4">
                    <div
                      class="
                        time-container
                        caption
                        px-1
                        mx-1
                        py-1
                        white--text
                        font-weight-bold
                        rounded-lg
                      "
                      :style="{ backgroundColor: daysList[dayNo].color }"
                    >
                      <p class="my-0 py-0 px-0 pb-2">
                        {{
                          moment(timetableItem.start_time, "hh:mm:ss").format(
                            "hh:mm A"
                          )
                        }}
                      </p>
                      <p class="my-0 py-0">
                        {{
                          moment(timetableItem.end_time, "hh:mm:ss").format(
                            "hh:mm A"
                          )
                        }}
                      </p>
                    </div>

                    <intract-drop-down-menu
                      v-if="!currentUser.is_student"

                      :options-list="getDropdownOptions(timetableItem)"
                    />
                  </div>
                </v-list-item-icon>
              </v-list-item>
            </v-card>
          </template>
        </intract-smart-list>

        <!-- FAB ADD BUTTON -->
        <v-fab-transition>
          <v-btn
          v-if="!currentUser.is_student"
            :color="daysList[dayNo].color"
            @click="timetableCreateSheet.visible = true"
            dark
            right
            bottom
            fixed
            fab
            class="non-focused"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>

        <!-- CREATE TIMETABLE ITEM EDIT SHEET -->
        <intract-create-edit-sheet
        v-if="currentUser.is_admin || currentUser.is_faculty"
          :title="
            timetableCreateSheet.editId
              ? 'Edit Timetable item'
              : 'Create a Timetable item'
          "
          :visible="timetableCreateSheet.visible"
          @close="
            timetableCreateSheet.visible = false;
            timetableCreateSheet.editId = null;
          "
          :fields="formFields"
          :submit-button-text="
            timetableCreateSheet.editId ? 'Edit item' : 'Create item'
          "
          :endpoint="endpoints.timetableViewSet"
          :data-object="timetableCreateSheet.obj"
          :create-success-message="timetableCreateSheet.createSuccessMessage"
          @updateObject="(obj) => (timetableCreateSheet.obj = obj)"
          @objectCreated="onRefresh"
          :edit-id="timetableCreateSheet.editId"
          :edit-success-message="timetableCreateSheet.editSuccessMessage"
        />

        <!-- Delete confirmation dialog -->
        <confirmation-dialog
          title="Are you sure you want to delete this Timetable item?"
          description="This action cannot be undone"
          :visible="showDeleteDialog"
          delete-dialog
          @successCallback="deleteTimetableItem"
          @failureCallback="showDeleteDialog = false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import TextHelper from '@utils/text_helper';


export default {
  name: "ViewTimetable",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarTitle],

  components: {
    IntractSmartList,
    IntractCreateEditSheet,
    IntractDropDownMenu,
    ConfirmationDialog,
  },

  data() {
    return {
      dayNo: this.$route.params.dayNo,
      moment,
      showDeleteDialog: false,
      toBeDeletedItem: null,
      TextHelper,
      timetableCreateSheet: {
        visible: false,
        createSuccessMessage: "Timetable item added succesfully!",
        editId: null,
        editSuccessMessage: "Timetable item edited succesfully!",
        obj: {
          day: this.$route.params.dayNo,
          room: this.$route.params.roomId,
          institution: null,
        },
      },
    };
  },

  computed: {
    daysList() {
      return {
        1: {
          color: "#54123B",
          name: "Monday",
        },
        2: {
          color: "#2F72CC",
          name: "Tuesday",
        },
        3: {
          color: "#00C853",
          name: "Wednesday",
        },
        4: {
          color: "#C81912",
          name: "Thursday",
        },
        5: {
          color: "#F57C00",
          name: "Friday",
        },
        6: {
          color: "#388E3C",
          name: "Saturday",
        },
        7: {
          color: "#29C7AC",
          name: "Sunday",
        },
      };
    },

    appBarTitle() {
      return this.daysList[this.dayNo].name;
    },

    timetableEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.timetableViewSet, [
        "room=" + this.$route.params.roomId,
        "day=" + this.dayNo,
      ]);
      return url;
    },

    formFields() {
      return {
        is_break: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Use this slot as Break/Recess",
          md: 12,
        },
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Subject",
          required: true,
          max: 255,
          md: 6,
          hide: this.timetableCreateSheet.obj.is_break,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Description (Optional)",
          required: false,
          md: 6,
          max: 500,
        },
        start_time: {
          fieldType: CreateEditFieldTypes.TIMEPICKER,
          textModel: null,
          label: "Start time",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.timetableCreateSheet.obj.end_time ||
              moment(this.timetableCreateSheet.obj.end_time, "HH:mm:ss").isAfter(
                moment(this.timetableCreateSheet.obj.start_time, "HH:mm:ss")
              ) ||
              "Start time should be before End time",
          ],
        },
        end_time: {
          fieldType: CreateEditFieldTypes.TIMEPICKER,
          textModel: null,
          label: "End time",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.timetableCreateSheet.obj.end_time, "HH:mm:ss").isAfter(
                moment(this.timetableCreateSheet.obj.start_time, "HH:mm:ss")
              ) ||
              "End time should be after Start time",
          ],
        },
      };
    },
  },

  methods: {
    getDropdownOptions(timetableItem) {
      var self = this;
      return [
        {
          value: 1,
          text: "Edit",
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.timetableCreateSheet.editId = timetableItem.id;
            // self.timetableCreateSheet.obj.institution = self.currentInstitution.id;
            self.timetableCreateSheet.visible = true;
          },
        },
        {
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedItem = timetableItem;
            self.showDeleteDialog = true;
          },
        },
      ];
    },

    async onRefresh() {
          var el = this.$refs["viewtimetable-smart-list"];
          if (el) el.getItems();
    },

    async timetableItemCreatedOrEdited(timetableObj) {
      // get object which is created or edited
      // determine if created or edited
      if (this.timetableCreateSheet.editId){
        // if edited: only update that item in the list without refreshing
        if (this.$refs['viewtimetable-smart-list']) {
          // console.log("Editing timetable item...")
          this.$refs['viewtimetable-smart-list'].replaceItem(timetableObj.id, timetableObj);
        }
      }else this.onRefresh();
    },

    async deleteTimetableItem() {
      var url = this.endpoints.timetableViewSet + this.toBeDeletedItem.id + "/";
      this.toBeDeletedItem = null;
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Timetable item has been successfully deleted!",
          type: "success",
        });
      }
      this.$refs["viewtimetable-smart-list"].getItems();
    },

  }, 

  created() {
    if (this.currentUser.is_admin || this.currentUser.is_faculty){
      this.timetableCreateSheet.obj.institution = this.currentInstitution.id;
    } 
  } 
}
</script>

<style scoped>
.time-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.time-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>